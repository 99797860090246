import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import type { StyleFunctionProps } from '@chakra-ui/styled-system'

// min-width
const breakpoints = {
  xs: '375px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
}

const theme = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  breakpoints,
  fonts: {
    logo: 'Comfortaa',
  },

  colors: {
    brand: {
      blue: '#00b4f0',
      pink: '#e62d8c',
      yellow: '#fac800',
    },
  },

  components: {
    Text: {
      variants: {
        logo: {
          fontFamily: 'logo',
          fontWeight: 700,
          fontSize: 'lg',
          letterSpacing: '0.1rem',
        },
      },
    },
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      '.md': {
        p: {
          my: 5,
          lineHeight: '1.6',
        },
        h2: {
          fontSize: { base: '2xl', md: '3xl' },
          borderBottom: 'solid 1px',
          borderBottomColor: mode('gray.400', 'gray.600')(props),
          mb: 5,
          pt: 16,
          pb: 2,
          fontWeight: 700,
        },
        h3: {
          fontSize: { base: '2xl', md: '3xl' },
          mb: 5,
          pt: 16,
          fontWeight: 700,
        },
        h4: {
          fontSize: { base: 'xl', md: '2xl' },
          mb: 5,
          pt: 16,
          fontWeight: 700,
        },
        h5: {
          fontSize: { base: 'l', md: 'xl' },
          mb: 5,
          mt: 8,
          fontWeight: 700,
        },
        h6: {
          fontSize: 'l',
          mb: 5,
          mt: 6,
          fontWeight: 700,
        },
        'ol, ul': {
          pl: 5,
          my: 5,
        },
        li: {
          my: 1,
          lineHeight: '1.6',
        },
        'pre.shiki': {
          py: 2,
          px: 3,
          overflowX: 'auto',
        },
        'p a, li a': {
          color: mode('blue.600', 'cyan.400')(props),
          pb: '0.1rem',
          transition: 'color 0.3s ease',
          _hover: {
            color: mode('cyan.600', 'cyan.300')(props),
            borderBottom: 'solid 1px',
          },
          _active: {
            pb: 0,
          },
        },
        table: {
          my: 5,
          borderCollapse: 'collapse',
          borderTop: 'solid 1px',
          borderLeft: 'solid 1px',
          borderColor: mode('gray.500', 'gray.500')(props),
          overflow: 'auto',
          maxWidth: '100%',
          width: '100%',
        },
        'thead, tfoot': {
          backgroundColor: mode('gray.100', 'gray.700')(props),
        },
        'th, td': {
          padding: 2,
          borderBottom: 'solid 1px',
          borderRight: 'solid 1px',
          borderColor: mode('gray.500', 'gray.500')(props),
        },
        th: {
          textAlign: 'center',
        },
        '.w-fit': {
          width: 'fit-content',
        },
        '.text-red': {
          color: mode('red.500', 'red.400')(props),
          fontWeight: 600,
        },
        '.text-green': {
          color: mode('green.500', 'green.300')(props),
          fontWeight: 600,
        },
        '.text-blue': {
          color: mode('blue.500', 'blue.400')(props),
          fontWeight: 600,
        },
        '.text-cyan': {
          color: mode('cyan.700', 'cyan.400')(props),
          fontWeight: 600,
        },
        '.text-orange': {
          color: mode('orange.500', 'orange.400')(props),
          fontWeight: 600,
        },
        '.text-pink': {
          color: mode('pink.500', 'pink.400')(props),
          fontWeight: 600,
        },
        '.text-gray': {
          color: mode('gray.500', 'gray.400')(props),
        },
        '.text-mute': {
          color: mode('gray.400', 'gray.700')(props),
        },
        '.text-bold, strong': {
          fontWeight: 700,
        },
        figure: {
          my: 5,
          mr: 'auto',
          ml: 'auto',
        },
        figcaption: {
          mt: 2,
          textAlign: 'center',
          color: mode('gray.600', 'gray.400')(props),
          fontSize: 'sm',
        },
        'p > code': {
          bg: mode('gray.100', 'gray.700')(props),
          px: 1,
          borderRadius: 'md',
          display: 'inline-block',
        },
        '.gallery': {
          display: 'grid',
          gridGap: '8px 8px',
          alignItems: 'center',
          my: 5,
          '& > figure': {
            m: 0,
          },
          '.gatsby-resp-image-wrapper': {
            width: '100%',
          },
          '&.columns-2': {
            gridTemplateColumns: 'repeat(2, 1fr)',
            '& > figcaption': {
              gridColumns: '1/3',
              mt: 3,
            },
          },
          '&.columns-3': {
            gridTemplateColumns: 'repeat(3, 1fr)',
            '& > figcaption': {
              gridColumns: '1/4',
            },
          },
          '&.columns-4': {
            gridTemplateColumns: 'repeat(4, 1fr)',
            '& > figcaption': {
              gridColumns: '1/5',
            },
          },
          '&.columns-5': {
            gridTemplateColumns: 'repeat(5, 1fr)',
            '& > figcaption': {
              gridColumns: '1/6',
            },
          },
        },
        blockquote: {
          background: mode('blue.50', 'blue.900')(props),
          padding: '0 1rem',
          overflow: 'hidden',
          borderLeft: 'solid 5px',
          borderLeftColor: mode('brand.blue', 'brand.blue')(props),
        },
        // '.gatsby-video-aspect-ratio': {
        //   mb: '-10%',
        // },
      },
      '.autolink-header-anchor.before': {
        top: 'auto',
        transform: 'translate(-100%, 50%)'
      },
      '.chakra-ui-dark .gatsby-image-wrapper': {
        filter: 'brightness(0.9);'
      }
    }),
  },
}
export default extendTheme(theme)
