import '@fontsource/comfortaa'
import * as FlexSearch from 'flexsearch'
import 'lazysizes'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __FLEXSEARCH__: any
  }
}

export function onClientEntry(): void {
  fetch(`${__PATH_PREFIX__}/flexsearch_index.json`)
    .then((response) => {
      return response.json()
    })
    .then(({ documents }) => {
      // Flexsearch
      const index = new FlexSearch.Document({
        preset: 'memory',
        tokenize: 'reverse',
        document: {
          index: ['title', 'keywords', 'author'],
          store: ['author', 'date', 'title'],
        },
      })
      documents.forEach((doc: SearchDocument) => {
        index.add(doc)
      })
      window.__FLEXSEARCH__ = index
    })
    .catch((err) => {
      console.error(err)
    })
}
